const bodyElement: HTMLBodyElement = document.querySelector('body') as HTMLBodyElement;
let scrollPosition = 0;

export function disableBodyScroll(): void {
    scrollPosition = window.pageYOffset;
    bodyElement.style.top = `-${scrollPosition}px`;
    bodyElement.classList.add('disableScroll');
}

export function enableBodyScroll(): void {
    document.documentElement.style.scrollBehavior = 'auto';
    bodyElement.classList.remove('disableScroll');
    bodyElement.style.removeProperty('top');
    window.scrollTo(0, scrollPosition);
    document.documentElement.style.removeProperty('scroll-behavior');
}
