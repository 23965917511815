import HttpClient from '../common/HttpClient';

export default class Modal {
    public modal: InteractableHTMLDialogElement;

    private readonly parentElement: HTMLElement;

    private backdrop: HTMLElement | null = null;

    private instantiated = false;

    private readonly outsideClickListener = (event: Event): void => {
        const target = event.target as HTMLElement;
        if (target.closest('dialog') === null) {
            this.close();
        }
    };

    constructor(modalElement: HTMLDialogElement, parentElement: HTMLElement = document.body) {
        const modal = modalElement as InteractableHTMLDialogElement;
        this.parentElement = parentElement;

        if (modal && modal.tagName === 'DIALOG') {
            this.modal = modal;
        } else {
            this.modal = this.create();
        }

        this.init();
    }

    private async init(): Promise<void> {
        await this.polyfillDialog();

        if (this.instantiated) {
            return;
        }

        this.setEventListeners();
    }

    private create(): InteractableHTMLDialogElement {
        const dialog = document.createElement('DIALOG') as InteractableHTMLDialogElement;
        this.parentElement.appendChild(dialog);

        return dialog;
    }

    public async show(): Promise<void> {
        await this.polyfillDialog();

        if (this.modal.open) {
            return;
        }

        if (this.modal.show) {
            this.modal.show();
        }

        this.backdrop = document.createElement('DIV');
        this.backdrop.classList.add('nh-backdrop');

        this.parentElement.appendChild(this.backdrop);

        this.modal.addEventListener('animationend', () =>
            document.addEventListener('click', this.outsideClickListener)
        );
    }

    public async open(): Promise<void> {
        await this.polyfillDialog();

        if (this.modal.open) {
            return;
        }

        if (this.modal.showModal) {
            this.modal.showModal();
        }
    }

    public close(): void {
        if (!this.modal.open) {
            return;
        }

        this.modal.classList.add('closing');

        if (this.backdrop) {
            this.backdrop.remove();
        }

        setTimeout(() => {
            if (this.modal.open) {
                this.modal.close();
            }

            this.modal.classList.remove('closing');

            const closeEvent = new CustomEvent('modal-closed', { detail: this.modal });
            this.modal.dispatchEvent(closeEvent);

            document.removeEventListener('click', this.outsideClickListener);
        }, 350);
    }

    public submitForm(
        form: HTMLFormElement,
        success?: GenericCallback,
        failure?: GenericCallback,
        method = 'post'
    ): Promise<void> {
        if (!form) {
            return Promise.reject();
        }

        const cssClass = 'text-danger';
        const action = form.getAttribute('action');
        const formData = new FormData(form);
        const postData = HttpClient.convertFormDataToQueryString(formData);
        const requestInit: RequestInit = {
            body: postData
        };

        if (!action) {
            return Promise.reject();
        }

        let errorElement = form.querySelector(`.${cssClass}`);
        if (errorElement) {
            errorElement.remove();
        }

        if (method === 'get') {
            return HttpClient.get(action, requestInit)
                .then(() => {
                    if (typeof success === 'function') {
                        success();
                    }
                })
                .catch((errorText: string) => {
                    errorElement =
                        form.querySelector(`.${cssClass}`) ||
                        this.createValidationElement(cssClass);
                    errorElement.textContent = errorText;

                    form.appendChild(errorElement);

                    if (typeof failure === 'function') {
                        failure();
                    }
                });
        }

        return HttpClient.post(action, postData)
            .then(() => {
                if (typeof success === 'function') {
                    success();
                }
            })
            .catch((errorText: string) => {
                errorElement =
                    form.querySelector(`.${cssClass}`) || this.createValidationElement(cssClass);
                errorElement.textContent = errorText;

                form.appendChild(errorElement);

                if (typeof failure === 'function') {
                    failure();
                }
            });
    }

    private setEventListeners(): void {
        this.modal.addEventListener('cancel', (event) => {
            event.preventDefault();
            this.close();
        });

        const closeButtons = [...this.modal.querySelectorAll('[data-close]')];

        if (closeButtons) {
            closeButtons.forEach((button) => {
                button.addEventListener('click', () => {
                    this.close();
                });
            });
        }

        this.instantiated = true;
    }

    private createValidationElement(cssClass: string): HTMLElement {
        const errorElement = document.createElement('P');
        errorElement.classList.add(cssClass);

        return errorElement;
    }

    private async polyfillDialog(): Promise<void> {
        if (typeof this.modal.showModal === 'function') {
            return Promise.resolve();
        }

        await import('dialog-polyfill').then((response: StandardObjectInterface) => {
            // eslint-disable-next-line no-prototype-builtins
            const dialogPolyfill = response.hasOwnProperty('default') ? response.default : null;

            if (dialogPolyfill === null) {
                return;
            }

            dialogPolyfill.registerDialog(this.modal);
        });

        return Promise.resolve();
    }
}
