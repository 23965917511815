import domEvents from '../events/domEvents';

export default class Module {
    public components: any[] = [];

    protected instantiatedComponents: Map<string, any> = new Map();

    public getComponent(name: string): any {
        return this.instantiatedComponents.get(name);
    }

    public init(): void {
        if (this.components.length > 0) {
            this.resolveComponents();
        }

        const handler = (e: CustomEvent): void => {
            this.resolveComponents(e.detail);
        };

        document.addEventListener(domEvents.templateInstantiated, handler);
    }

    protected resolveComponents(searchElement: HTMLElement | Document = document): void {
        const componentElements: HTMLElement[] = Array.from(
            searchElement.querySelectorAll('[data-nh-component]')
        ) as HTMLElement[];

        componentElements.forEach((componentElement: HTMLElement) => {
            let componentName = componentElement.getAttribute('data-nh-component');
            if (!componentName) {
                return;
            }

            componentName = Module.toPascalCase(componentName);

            const foundComponent: any = this.components.find((c) => {
                if (typeof c.getClassName === 'undefined') {
                    throw new Error(
                        `Class ${c} does not have required static method "getClassName"`
                    );
                }

                return c.getClassName() === componentName;
            });

            if (foundComponent) {
                const component = this.#instantiate(componentElement, foundComponent);
                this.instantiatedComponents.set(componentName, component);
            }
        });
    }

    #instantiate(componentElement: HTMLElement, foundComponent: any): any {
        const context: ContextInterface = {
            element: componentElement,
            bindings: this.#setDataBindings(componentElement)
        };
        const componentInstance: any = this.#instantiateComponent(foundComponent, context);
        Object.keys(context.bindings).forEach((binding) => {
            let bindingValue: string = context.bindings[binding];

            Reflect.defineProperty(context.bindings, binding, {
                set(value) {
                    bindingValue = value;
                },
                get() {
                    return bindingValue;
                },
                configurable: false
            });
        });

        this.resolveComponents(componentElement);

        if (componentInstance.onInit) {
            componentInstance.onInit();
        }

        componentElement.removeAttribute('data-nh-component');

        return componentInstance;
    }

    #setDataBindings(element: HTMLElement): StandardObjectInterface {
        const dataset: DOMStringMap = element.dataset;
        const bindings: StandardObjectInterface = {};

        Object.keys(dataset).forEach((i) => {
            if (i.startsWith('bind')) {
                const bindingName = Module.formatBinding(i);
                bindings[bindingName] = dataset[i];
            }
        });

        return bindings;
    }

    #instantiateComponent(FoundComponent: any, context: ContextInterface): any {
        return new FoundComponent(context);
    }

    static formatBinding(str: string): string {
        return str.substring(4, 5).toLowerCase() + str.slice(5);
    }

    static toPascalCase(str: string): string {
        return str
            .split('-')
            .map((p) => `${p.charAt(0).toUpperCase()}${p.slice(1)}`)
            .join('');
    }
}
